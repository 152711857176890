/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import mobileMockup from "../../../assets/why-myserviceagent.webp";
import saveCostsIcon from "../../../assets/icons/landingpage/costs.svg";
import analyticsIcon from "../../../assets/icons/landingpage/analytics.svg";
import callsIcon from "../../../assets/icons/landingpage/calls.svg";
import globeIcon from "../../../assets/icons/landingpage/globe.svg";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SubscribeForm from "../../../components/SubscribeForm";
import AltSchool from "../../../assets/partner-logos/alt_school.svg";
import Opay from "../../../assets/partner-logos/opay.svg";
import FlagIt from "../../../assets/partner-logos/flagit.svg";
import Bumpa from "../../../assets/partner-logos/bumpa.svg";
import Truq from "../../../assets/partner-logos/truq.svg";
import RovingHeights from "../../../assets/partner-logos/rovingheights.svg";
import Boocavilla from "../../../assets/partner-logos/boccaville.svg";
import Ursafe from "../../../assets/partner-logos/ursafe.svg";
import Topship from "../../../assets/partner-logos/Topship.svg";
import Exaf from "../../../assets/partner-logos/exaf.svg";
import Grocedy from "../../../assets/partner-logos/grocedy.svg";
import Scrapays from "../../../assets/partner-logos/scrapays.svg";
import Upsillon from "../../../assets/partner-logos/upsillon.svg";
import Hooman from "../../../assets/partner-logos/Hooman.webp";
import Medplus from "../../../assets/partner-logos/medplus.jpeg";
import Nownow from "../../../assets/partner-logos/nownow.svg";
import Code from "../../../assets/code.svg";
import NextArrow from "../../../assets/next.svg";
import PrevArrow from "../../../assets/prev.svg";
import CountUp from "react-countup";
import axios from "../../../utils/axios-base";
import OTP from "../../../assets/images/about/otp_ico.svg";
import NewMessage from "../../../assets/images/about/new-message.svg";
import CustomerInsight from "../../../assets/images/about/customer-insight.svg";
//====  Quick Note ====\\

/* 

When writing React, it’s often good not to think of how you want to accomplish a result,
but instead what the component should look like in it’s new state.
This sets us up for a good control flow where state goes through a series of predictable and replicable mutations


*/

const testimonialItems = [
  {
    name: "Ose Enaholo",
    order: 1,
    profession: "AFF Program Officer ( Monitoring and Evaluation)",
    text: "Working with MyServiceAgent (MSA) to deliver voice OTPs to our users has made our app onboarding process hitch-free so far. I will recommend them to people seeking for similar services.",
  },
  {
    name: "Ayodeji Adegoroye ",
    order: 0,
    profession: "Helppo Africa (Operations manager)",
    text: "Myserviceagent has amazing customer support. The phone book creation feature saves a lot of time and it’s quite cheap considering other options",
  },
  {
    name: "Ayodele Ogunkunle",
    order: 2,
    profession: "Bumpa (Digital Marketing Lead)",
    text: "Our business involves constantly communicating with small business owners who don't necessarily read their emails, MSA SMS platform has helped us effectively reach these customers, even those on DND. Awesome service with excellent client support.",
  },
];

const sectionTwoCardContent = [
  {
    icon: globeIcon,
    header: "Wider Reach",
    content:
      "Reach more customers than with in-person sales calls. Engage with thousands of customers at a go!",
  },
  {
    icon: saveCostsIcon,
    header: "Save Costs",
    content:
      "Create a personal rapport with your customers and communicate frequently at low prices",
  },
  {
    icon: analyticsIcon,
    header: "Real time analytics",
    content:
      "Understand customers’ concerns, complaints, and enquiries on a human facing dashboard for intelligent decision making",
  },
  {
    icon: callsIcon,
    header: "Multiple out & inbound calls",
    content:
      "Simultaneously interact with thousands of customers in a personalized manner, just like a human agent.",
  },
];

const partners = [
  {
    logo: AltSchool,
    text: "Alt School",
  },
  {
    logo: Opay,
    text: "Opay",
  },
  {
    logo: FlagIt,
    text: "FlagIt",
  },
  {
    logo: Bumpa,
    text: "Bumpa",
  },
  {
    logo: Truq,
    text: "Truq",
  },
  {
    logo: RovingHeights,
    text: "RovingHeights",
  },
  {
    logo: Boocavilla,
    text: "Boocaville",
  },
  {
    logo: Ursafe,
    text: "Ursafe",
  },
  {
    logo: Nownow,
    text: "NowNow",
  },
  {
    logo: Hooman,
    text: "Hooman",
  },
  {
    logo: Topship,
    text: "Topship",
  },
  {
    logo: Medplus,
    text: "medplus",
  },
  {
    logo: Upsillon,
    text: "Upsillon",
  },
  {
    logo: Exaf,
    text: "ExtensionAfrica",
  },
  {
    logo: Grocedy,
    text: "Grocedy",
  },
  {
    logo: Scrapays,
    text: "Scrapays",
  },
];

const products = [
  {
    title: "Voice Business Messaging (Telemarketing)",
    text: "Transform your customer experience by sending messages to your customers in your own voice.",
    link: "/business-messaging",
  },
  {
    title: "Voice Survey",
    text: "Conduct customer survey using Voice. Collect feedback and have access to results and basic data for analytics",
    link: "/voice-survey",
  },
  {
    title: "Voice OTP Authentication",
    text: "Secure your customer accounts and leave no trail for hackers to copy. No delay, No restrictions, No hidden charges!",
    link: "/otpauthentication",
  },
  {
    title: "Virtual Call Center",
    text: "Resolve basic enquires, FAQ and complaints 24/7 without needing a physical agent. With your call center in the cloud, communicate with over 1000 customers simultaneously.",
    link: "/call-center",
  },
];

const LandingPage = () => {
  const [current, setCurrent] = useState(0);
  const [tesimonials, setTesimonials] = useState(testimonialItems);
  const [slideSide, setSlideSide] = useState("next");

  const delay = 6000;
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => slideNavigation(slideSide), delay);

    return () => {
      resetTimeout();
    };
  }, [current]);

  const slideNavigation = (slideType) => {
    setSlideSide(slideType);
    const currentItem =
      slideType === "next"
        ? current === testimonialItems.length - 1
          ? 0
          : current + 1
        : current === 0
        ? testimonialItems.length - 1
        : current - 1;

    switcher(currentItem);
  };

  const switcher = (currentItem) => {
    let newTestimonial = [...testimonialItems];
    switch (currentItem) {
      case 0:
        newTestimonial[0].order = 1;
        newTestimonial[1].order = 0;
        newTestimonial[2].order = 2;
        break;
      case 1:
        newTestimonial[0].order = 2;
        newTestimonial[1].order = 1;
        newTestimonial[2].order = 0;
        break;
      case 2:
        newTestimonial[0].order = 0;
        newTestimonial[1].order = 2;
        newTestimonial[2].order = 1;
        break;
      default:
        break;
    }
    setTesimonials(newTestimonial);
    setCurrent(currentItem);
  };

  const [metrics, setMetrics] = React.useState(null);

  React.useEffect(() => {
    const getMetrics = async () => {
      try {
        const res = await axios.get("/social-proof");

        if (res.status === 200) {
          setMetrics(res.data);

          console.log(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMetrics();

    // setInterval(() => {
    //   getMetrics();
    // }, 20000);
  }, []);

  const metricsArray = [
    {
      icon: NewMessage,
      number: "50,000+",
      text: "Business messages sent",
    },
    {
      icon: CustomerInsight,
      number: "100+",
      text: "Businesses served",
    },
    {
      icon: OTP,
      number: "4,000+",
      text: "OTP transactions completed",
    },
  ];

  return (
    <>
      <Header bgStyle="landing__page--bg" theme="darkTheme">
        <div className={style["new--header__details"]}>
          <div>
            <h3>Do it all with Voice AI</h3>
            <p className={style["third__header__paragraph"]}>
              Engage customers at every step of their journey with our
              intelligent customer engagement platform for African businesses.
            </p>
            <Link to="/sign-up" style={{ display: "flex" }}>
              <Button className="medium--filled">Get Started</Button>
            </Link>
          </div>
        </div>
      </Header>

      {/* Added by Bobola */}

      {/* Added by Bobola */}

      <div className={style["trusted--partner"]}>
  <h3 className={style["trusted--partner__title"]}>
    Trusted by World Class Businesses
  </h3>
  <div className={style["trusted--partner__logos"]}>
    {partners.slice(0, 8).map((partner, index) => (
      <img src={partner.logo} alt={partner.text} key={index} />
    ))}
  </div>
  <div className={style["trusted--partner__logos2"]}>
    {partners.slice(8, 16).map((partner, index) => (
      <img src={partner.logo} alt={partner.text} key={index} />
    ))}
  </div>
</div>


      <div className={style["metrics--section"]}>
        <h3 className={style["metrics--section--title"]}>
          A look at the numbers we’ve done so far
        </h3>
        <div className={style["metric__items"]}>
          {metricsArray.map((item, index) => (
            <div className={style["metric__item"]} key={index}>
              <div className={style["icon"]}>
                {" "}
                <img src={item.icon} alt={item.text} />
              </div>
              <h3 className={style["number"]}>
                {item.icon === NewMessage ? (
                  <CountUp
                    className={style["number"]}
                    start={0}
                    end={metrics?.totalSmsJobs}
                    duration={3}
                    separator=","
                  />
                ) : item.icon === OTP ? (
                  <CountUp
                    className={style["number"]}
                    start={0}
                    end={metrics?.totalVoiceJobs}
                    duration={3}
                    separator=","
                  />
                ) : (
                  <CountUp
                    className={style["number"]}
                    start={0}
                    end={metrics?.totalCustomers}
                    duration={3}
                    separator=","
                  />
                )}
              </h3>
              <p className={style["text"]}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      <section className={style["section__one"]}>
        <div className={style["container"]}>
          <h3>Products built with your business in mind</h3>

          <div className={style["products"]}>
            {products.map((product, index) => (
              <div className={style["products__card"]} key={index}>
                <h3>{product.title}</h3>
                <p>{product.text}</p>
                <Link to={product.link}>
                  <Button className="medium--filled">Learn More</Button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={style["section__two"]}>
        <h3>Why MyServiceAgent?</h3>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            {/* <div className={style["section__two--img"]}> */}
            <img
              src={mobileMockup}
              alt="mobileMockup"
              height="100%"
              width="100%"
            />
            {/* </div> */}
          </div>

          <div className={style["container--right"]}>
            <div className={style["container__cards--row"]}>
              {sectionTwoCardContent.map((item, id) => (
                <div className={style["container__card"]} key={id}>
                  <div className={style["container__card--content"]}>
                    <img
                      src={item.icon}
                      alt="Icon"
                      height="100%"
                      width="auto"
                    />
                    <p>{item.header}</p>
                  </div>
                  <p>{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={style["section__three"]}>
        <h3>A glimpse of our happy customers</h3>
        <div>
          <img
            onClick={() => slideNavigation("prev")}
            src={NextArrow}
            alt="next slide arrow"
            className={style["next--arrow"]}
          />
        </div>
        <div
          onClick={() => slideNavigation("next")}
          className={style["prev--arrow"]}
        >
          <img src={PrevArrow} alt="prev slide arrow" />
        </div>
        <div className={style["testimonial--cards"]}>
          {tesimonials.map((testimonial, index) => (
            <div
              className={`${
                index === current
                  ? style["testimonial--card--active"]
                  : style["testimonial--card"]
              } ${
                testimonial.order === 1
                  ? style["order-1"]
                  : testimonial.order === 2
                  ? style["order-2"]
                  : style["order-0"]
              } ${
                slideSide === "next" && index === current
                  ? style["next"]
                  : slideSide === "prev" && index === current
                  ? style["prev"]
                  : ""
              }`}
              key={index}
            >
              <svg
                width="60"
                height="46"
                viewBox="0 0 60 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={
                    index === current ? { fill: "#fff" } : { fill: "#000000" }
                  }
                  d="M5.78576 41.7367C2.35243 38.09 0.509094 34 0.509094 27.37C0.509094 15.7033 8.69909 5.24666 20.6091 0.0766602L23.5858 4.66999C12.4691 10.6833 10.2958 18.4867 9.42909 23.4067C11.2191 22.48 13.5624 22.1567 15.8591 22.37C21.8724 22.9267 26.6124 27.8633 26.6124 34C26.6124 37.0942 25.3833 40.0616 23.1953 42.2496C21.0074 44.4375 18.04 45.6667 14.9458 45.6667C11.3691 45.6667 7.94909 44.0333 5.78576 41.7367ZM39.1191 41.7367C35.6858 38.09 33.8424 34 33.8424 27.37C33.8424 15.7033 42.0324 5.24666 53.9424 0.0766602L56.9191 4.66999C45.8024 10.6833 43.6291 18.4867 42.7624 23.4067C44.5524 22.48 46.8958 22.1567 49.1924 22.37C55.2058 22.9267 59.9458 27.8633 59.9458 34C59.9458 37.0942 58.7166 40.0616 56.5287 42.2496C54.3407 44.4375 51.3733 45.6667 48.2791 45.6667C44.7024 45.6667 41.2824 44.0333 39.1191 41.7367Z"
                  fill="white"
                />
              </svg>

              <p className={style["testimonial--card__text"]}>
                {testimonial.text}
              </p>

              <div className={style["card--footer"]}>
                <div className="circle">
                  <svg
                    width="42"
                    height="43"
                    viewBox="0 0 42 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      style={
                        index === current
                          ? { fill: "#fff" }
                          : { fill: "#969696" }
                      }
                      d="M20.9235 0.635742C32.4235 0.635742 41.7569 9.96908 41.7569 21.4691C41.7569 32.9691 32.4235 42.3024 20.9235 42.3024C9.42354 42.3024 0.09021 32.9691 0.09021 21.4691C0.09021 9.96908 9.42354 0.635742 20.9235 0.635742ZM8.47146 28.5857C11.5298 33.1482 16.1215 36.0524 21.2569 36.0524C26.3902 36.0524 30.984 33.1503 34.0402 28.5857C30.573 25.3453 26.0026 23.5458 21.2569 23.5524C16.5104 23.5452 11.9392 25.3448 8.47146 28.5857V28.5857ZM20.9235 19.3857C22.5811 19.3857 24.1709 18.7273 25.343 17.5552C26.5151 16.3831 27.1735 14.7933 27.1735 13.1357C27.1735 11.4781 26.5151 9.88843 25.343 8.71633C24.1709 7.54422 22.5811 6.88574 20.9235 6.88574C19.2659 6.88574 17.6762 7.54422 16.5041 8.71633C15.332 9.88843 14.6735 11.4781 14.6735 13.1357C14.6735 14.7933 15.332 16.3831 16.5041 17.5552C17.6762 18.7273 19.2659 19.3857 20.9235 19.3857V19.3857Z"
                      fill="#969696"
                    />
                  </svg>
                </div>
                <div className={style["customer--details"]}>
                  <p className={style["customer--details__name"]}>
                    {testimonial.name}
                  </p>
                  <p className={style["customer--details__company"]}>
                    {testimonial.profession}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={style["testimonial--controls"]}>
          {testimonialItems.map((testimonial, index) => (
            <div
              onClick={() => switcher(index)}
              className={
                index === current
                  ? style["testimonial--control__active"]
                  : style["testimonial--control"]
              }
              key={index}
            ></div>
          ))}
        </div>
      </section>

      <section className={style["section__four"]}>
        <div className={style["container"]}>
          <div className={style["container--left"]}>
            <p>For Developers</p>
            <h3>Easy-to-use, powerful APIs suited for every developer</h3>
            <a
              target="_blank"
              href="https://documentation.myserviceagent.net/"
              rel="noopener noreferrer"
            >
              Visit our API Docs
              <span style={{ marginRight: "23px" }}>
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    style={{ fill: "#126ABD" }}
                    d="M14.3386 4.552L2.86263 16.028L0.977295 14.1427L12.452 2.66667H2.33863V0H17.0053V14.6667H14.3386V4.552Z"
                    fill="#126ABD"
                  />
                </svg>
              </span>
            </a>
          </div>
          <div className={style["container--right"]}>
            <div className={style["container--right__image"]}>
              <img src={Code} alt="mobileMockup" height="100%" width="100%" />
            </div>
          </div>
        </div>
      </section>

      <SubscribeForm />

      <section className={style["last__section"]}>
        <div className={style["details"]}>
          <div>
            <h3>Do it all, Do it now</h3>
            <p>
              Start today to intelligently engage and address customer issues
              with little or no human support with our Intelligent Customer
              Engagement platform
            </p>
            <Link to="/sign-up" style={{ display: "flex" }}>
              <button>Get Started for Free</button>
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LandingPage;

//In summary one of the benefits of shorter and compact code is the ability to spot bugs faster and improves readablity
